export const addStudentValues = {
  admission_no: "",
  student_name: "",
  class_name: "",
  section: "",
  roll_no: "",
  father_name: "",
  mother_name: "",
  date_of_birth: "",
  address: "",
  alternative_phone_no: "",
  father_occupation: "",
  mother_occupation: "",
  religion: "",
  gender: "",
  email: "",
  password: "",
  phone: "",
  date_of_admission: "",
  reserve_category: "",
  student_image: null,
  blood_group: "",
  quota: "",
  ews: "",
  bpl: "",
  height: "",
  weight: "",
};
export const addStaffValues = {
  staff_name: "",
  password: "",
  phone: "",
  email: "",

  address: "",
  gender: "",
  religion: "",
  avatar: null,

  role: "",
  designation: "",
  qualification: "",
  experience: "",
  bacnk_account_number: "",
  ifsc_code: "",
  pan_card_no: "",
  joining_date: "",
};
export const createFeesValues = {
  student_id: "",
  basic_fees: "",
  due_basic_fees: "",
  tution_fees: "",
  due_tution_fees: "",
  total_bus_fees: "",
  paid_bus_fees: "",
  due_bus_fees: "",
  parking_fees: "",
  due_parking_fees: "",
  total_fees: "",
  paid_fees: "",
  due_fees: "",
  date_time: "",
  payment_method: "",
  months: "",
};

export const createFeeStructureValues = {
  class_value: "",
  basic_fees: "",
  tution_fees: "",
  parking_fees: "",
  obc_relaxation: "",
  sc_relaxation: "",
  ews_relaxation: "",
  bpl_relaxation: "",
  st_relaxation: "",
  quota_relaxation: "",
  other_realxation: "",
  due_date: "",
};

export const createSalaryValues = {
  staff_id: "",
  basic_salary: "",
  hra: "",
  pa: "",
  da: "",
  extra_allowance: "",
};
export const paySalaryValues = {
  staff_id: "",
  amount: "",
  bonus: "",
  leave_in: "2",
  total_amount: "",
  date: "",
  status: "",
};
export const booksValues = {
  book_name: "",
  quantity: "",
  price: "",
  row_no: "",
  section: "",
  subject: "",
  class_name: "",

};
export const issueBooksValues = {
  book_name: "",
  student_id:"",
  issue_date: "",
  return_date: "",
  price: "",
  day: "",

};
export const EditMarksValues = {
  subject_name: "",
  pen_paper_term1_pt1: "",
  multiple_assessment_term1_pt1: "",
  pen_paper_term1_pt2: "",
  multiple_assessment_term1_pt2: "",
  pen_paper_term1_pt3: "",
  multiple_assessment_term1_pt3: "",
  best_of_two_term1: "",
  weightage_term1: "",
  portfoilo_term1: "",
  sub_enrich_act_term1: "",
  hly_exam_term1: "",
  total_marks_term1: "",
  final_grade_term1: "",
  pen_paper_term2_pt1: "",
  multiple_assessment_term2_pt1: "",
  pen_paper_term2_pt2: "",
  multiple_assessment_term2_pt2: "",
  pen_paper_term2_pt3: "",
  multiple_assessment_term2_pt3: "",
  best_of_two_term2: "",
  weightage_term2: "",
  portfoilo_term2: "",
  sub_enrich_act_term2: "",
  annual_exam_term2: "",
  total_marks_term2: "",
  final_grade_term2: "",
};
